
.certificate-container {
   
    width: 100%;
   height: 100%;
  
}

.certificate {
    border: 4px solid #FA8072;
    border-radius: 12px;
    padding: 25px;
    height: 600px;
    position: relative;
   
    background-image: 
      url('/public/Images/cer-back.jpg');
      /* Background image */
    
    background-position: center;
    background-size: cover;
}


.certificate-header > .logo {
    width: 80px;
    height: 80px;
    
}

.certificate-title {
    text-align: center;  
    color: #B94B36;  
}

.certificate-body {
    text-align: center;
}

.certificate-body h1{

    /* font-weight: 400; */
    font-size: 48px;
    color: #B94B36;
   
}

.student-name {
    font-size: 24px;
    font-weight: 700    ;
}

.certificate-content {
    margin: 0 auto;
    width: 750px;
}

.about-certificate {
    width: 380px;
    margin: 0 auto;
}

.certificate-content p{

    font-size: 18px;
    font-weight: 500;


}

.topic-description {

    text-align: center;
}


