.leader-board-wrapper {
   padding: 20px;
   max-width: 900px;
   width: 100%;
   
   
   border: 2px solid salmon;
   background-color: #FCD9A3a3;
   border-radius: 12px;
   overflow: hidden;
   overflow-y: auto;
   scrollbar-width: none;
   scroll-behavior: auto;
   margin-bottom: 20px;
   
    
}

.leader-card{
    background-color: #F9BB88 ;
    margin-top: 10px ;
    padding: 20px ;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: inset #fff 0px 0px 40px -12px; 


}

.leader-img img {
    border: 2px solid saddlebrown;
    width: 50px;
    height: 50px;
    border-radius: 50%;

}

.winners.leader-card:nth-child(1) {
    background-color: #F7CD70;
}

.winners.leader-card:nth-child(2) {
    background-color: #61E6F0;
}

.winners.leader-card:nth-child(3) {
    background-color: #FD7780;
}


.leader-rank {
    font-size: 24px;
    font-weight: 600;
    color: #B94B36;
}

.leader-card h3 {
    font-size: 24px;
    font-weight: 600;
    color: #B94B36;
}


.score-section {
    padding: 10px;
    background: rgba(255, 255, 255, 0.07);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    color: #1e1e1e;
}

.score-section  p {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 600;
    margin-left: 12px; 
}

.score-section img {
    width: 40px;
    height: 40px;
    left: -20px;
    position: absolute;
}


.leader-board-container{
    height: 100%;
}

/* Pagination Container */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0; /* Adjust as needed */
  }
  
  /* Pagination Items */
  .pagination a,
  .pagination span {
    margin: 0 5px; /* Space between buttons */
    padding: 8px 12px; /* Padding for clickable areas */
    border: 1px solid #007bff; /* Border color */
    border-radius: 5px; /* Rounded corners */
    background-color: #f8f9fa; /* Default background color */
    color: #007bff; /* Default text color */
    text-decoration: none; /* Remove underline from links */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
    display: inline-block; /* Ensure buttons are inline-block */
  }
  
  /* Active Page */
  .pagination .active {
    background-color: #007bff; /* Active page background color */
    color: white; /* Active page text color */
    border: 1px solid #007bff; /* Same border to avoid size changes */
  }
  
  /* Disabled State */
  .pagination .disabled {
    color: #ccc; /* Color for disabled pagination items */
    pointer-events: none; /* Disable pointer events */
  }
  
  /* Hover Effects */
  .pagination a:hover:not(.active) {
    background-color: #e2e6ea; /* Background color on hover */
    color: #0056b3; /* Text color on hover */
  }
  
  /* Previous and Next Buttons */
  .pagination .previous,
  .pagination .next {
    font-weight: bold; /* Make previous and next buttons bold */
  }
  
  /* Break Label */
  .pagination .break-me {
    padding: 8px 12px; /* Padding for break label */
    color: #007bff; /* Color for break label */
  }
  

@media screen and (max-width: 600px) {
    .leader-card h3 {
        font-size: 16px;
        font-weight: 600;
        
    }
    
    .score-section p {
        font-size: 14px;
        
      
    }

    .leader-rank {
        font-size: 16px;
    }
    
   
}

