.landing-wrapper {
  position: relative;
  background-image: url("../public/Images/background.jpg") !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100%;
  background-attachment: fixed;
}

.landing-container {
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}

.logo {
  width: 75px;
  height: 75px;
  mix-blend-mode: darken;
}

.header-buttons {
  display: flex;
  gap: 20px;
  align-items: center;
}

.register-button {
  cursor: pointer;
  border: none;
  padding: 10px 30px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  border-radius: 30px;
  border-bottom: 3px solid #e05567;
  background: #fe697d;
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
}


.certi-button {
  display: flex;
 gap: 5px;
  align-items: center;
 
  color: white;
 
}

.login-button {
  cursor: pointer;
  border: none;
  padding: 10px 30px;
  background: #0b743d;
  color: white;
  font-weight: 500;
  font-size: 14px;
  border-radius: 30px;
  border-bottom: 3px solid #024d25;
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
}

.login-button:hover,
.register-button:hover {
opacity: 0.7;
}

/* .card-first:hover,
.card-second:hover {
  transform: scale(1.1);
} */

.cat-img {
  height: 500px;
  margin-left: 50px;
  animation: moveUpDown 3s ease-in-out infinite;
}

.card-first {
  background-image: url("../public/Images/cover1.png") !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 220px;
  width: 100%;
  transition: transform 0.3s ease;
}

.card-second {
  background-image: url("../public/Images/cover2.png") !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 220px;
  width: 100%;
  margin-top: 30px;
  margin-left: -40px;
  transition: transform 0.3s ease;
}

.cloud-right {
  height: 28px;
  margin-top: 39px;
  margin-bottom: 81px;
  margin-left: 35px;
  animation: moveUpDown 4s ease-in-out infinite;
}

.ball-right {
  height: 24px;
  display: block;
  animation: moveUpDown 2s ease-in-out infinite;
}

.ball-right {
  height: 24px;
  display: block;
  animation: moveUpDown 2s ease-in-out infinite;
}

.blue-ball-left {
  margin-top: 39px;
  height: 26px;
  display: block;
  margin-left: 70px;
  animation: moveUpDown 2s ease-in-out infinite;
}

.cards-conatiner {
  margin-top: -50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.red-ball-left {
  height: 23px;
  display: block;
  animation: moveUpDown 2s ease-in-out infinite;
}

.cloud-left {
  height: 42px;
  margin-top: 77px;
  margin-bottom: 81px;
  margin-left: 35px;
  animation: moveUpDown 4s ease-in-out infinite;
}

.side-info {
  margin-bottom: 10px;
  position: relative;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content .info {
  width: 100%;
}

.description {
  margin-bottom: 12px;
  font-size: 16px;
  color: rgb(110, 61, 5);
  max-width: 475px;
}

.head-name {
  font-size: 50px;
  position: relative;
  padding-top: 30px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 30px;
}

.head-name span {
  color: #fe697d;
}

.page-not-found {
position: relative;
}

.page-not-found-content {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
}

.page-not-found-content h2 {
  font-size: 40px;
  margin-top: -40px;
  font-weight: 600;
}

.page-not-found-content img {
  max-width: 500px;
  width: 100%;
  margin: 0px auto;
  mix-blend-mode: darken;
}


.page-not-found-content a {
  cursor: pointer;
  border: none;
  padding: 10px 30px;
  background: #0b743d;
  color: white;
  font-weight: 500;
  font-size: 14px;
  border-radius: 30px;
  border-bottom: 3px solid #024d25;
  background: linear-gradient(to right, black 50%, #0b743d 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
  text-decoration: none;
  margin-top: 10px;
}



.card-cover-1 {
  max-width: 700px;
  width: 100%;
  animation: moveUpDown 2s ease-in-out infinite;
  position: relative;

}

.card-cover-1 span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  min-width: 135px;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.5s;
  border: none;
  padding: 10px 20px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  border-radius: 30px;
  border-bottom: 3px solid #e05567;
  background: #fe697d;
  transition: all 0.5s ease-out;

}

.card-cover-1 a, .card-cover-2 a {
  display: flex;
}


.card-cover-1:hover span{
  opacity: 1;
}

.card-cover-1 img, .card-cover-2 img, .card-cover-3 img, .card-cover-4 img {
  width: 100%;
  transition: all 0.5s ;
  border-radius: 12px;
}

.card-cover-1:hover img, .card-cover-2:hover img, .card-cover-3:hover img,  .card-cover-4:hover img {
  filter: brightness(0.5);
  
}

.card-cover-2 {
  max-width: 350px;
  width: 100%;
  animation: moveUpDown 2s ease-in-out infinite;
  position: relative;
  z-index: 9;

}


.card-cover-2 span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  min-width: 135px;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.5s;
  border: none;
  padding: 10px 20px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  border-radius: 30px;
  border-bottom: 3px solid #e05567;
  background: #fe697d;
  transition: all 0.5s ease-out;

}
  

.card-cover-2:hover span{
  opacity: 1;
}

.card-cover-2:hover img {
  filter: brightness(0.5);
  
}

.card-cover-2 img {
  width: 100%;
  transition: all 0.5s ;
}




.card-cover-3 {
  max-width: 350px;
  width: 100%;
  animation: moveDownUp 2s ease-in-out infinite;
  position: relative;

}


.card-cover-3 span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  min-width: 135px;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.5s;
  border: none;
  padding: 10px 20px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  border-radius: 30px;
  border-bottom: 3px solid #e05567;
  background: #fe697d;
  transition: all 0.5s ease-out;

}
  

.card-cover-3:hover span{
  opacity: 1;
}

.card-cover-3:hover img {
  filter: brightness(0.5);
  
}

.card-cover-3 img {
  width: 100%;
  transition: all 0.5s ;
}











.card-cover-4 {
  max-width: 350px;
  width: 100%;
  animation: moveDownUp 2s ease-in-out infinite;
  position: relative;

}


.card-cover-4 span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  min-width: 135px;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.5s;
  border: none;
  padding: 10px 20px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  border-radius: 30px;
  border-bottom: 3px solid #e05567;
  background: #fe697d;
  transition: all 0.5s ease-out;

}
  

.card-cover-4:hover span{
  opacity: 1;
}

.card-cover-4:hover img {
  filter: brightness(0.5);
  
}

.card-cover-4 img {
  width: 100%;
  transition: all 0.5s ;
}

.game-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);
}


.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 49.25%; 
  height: 0;
  overflow: hidden;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}


.auth-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}

.login-cover img {
  margin: 0;
  height: auto;
  width: 100%;
}

.auth-popup .modal-dialog {
  max-width: 700px;
}

.login-cover {
  max-width: 300px;
}

.login-form {
  width: 350px;
}

.auth-popup .modal-header {
  border: none;
  padding: 0;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 9;
}
select.form-select {
  background-color: #f0deb3;
  border: none;
  padding: 15px 20px;
  border-radius: 30px;
}
.auth-popup .modal-body {
  padding: 35px 30px;
}

.auth-popup .modal-content {
  background: #fff3d5;
}

.auth-container .form-control, .auth-container .form-control:focus {
  background: #f0deb3;
  border: none;
  padding: 15px 20px;
  border-radius: 30px;
}

.auth-container form {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.full-w {
  width: 100%;
}

.half-w {
  width: 48%;
}


.auth-container h2 {
  font-size: 25px;
  font-weight: 600;
  padding-bottom: 20px;
  margin: 0;
}


.auth-container .auth-btn, .auth-container .auth-btn:hover, .auth-container .auth-btn:focus, .auth-container .auth-btn:focus:not(:focus-visible) {
  height: 55px;
  font-size: 20px;
  border: none;
  padding: 10px 30px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  border-radius: 30px;
  border-bottom: 3px solid #e05567;
  background: #fe697d;
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
  width: 100%;

}

.auth-container .auth-btn:hover{
  opacity: 0.7;
}


.react-date-picker {
  width: 100%;
}
.react-date-picker__wrapper {

  background: #f0deb3;
  border: none!important;
  padding: 15px 20px;
  border-radius: 30px;
  width: 100%;
  height: 54px;
  align-items: center;

}

.react-calendar__navigation button:disabled {
  background-color: transparent !important;
  color: #888888a3
}

.react-date-picker__inputGroup__divider, .react-date-picker__inputGroup__leadingZero, .react-date-picker__inputGroup__input {
  color: #212529;
}


.react-calendar {
  background: #fff3d5 !important;
  border: 1px solid #f0deb3 !important;
  font-family: "Jost", sans-serif !important;
  border-radius: 8px !important;
}

.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
  background-color: #fff3d5 !important;
}

.react-date-picker__button .react-date-picker__button__icon, .react-date-picker__button:enabled:hover .react-date-picker__button__icon, .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #a89a79 !important;
}

.react-date-picker__inputGroup__input{
  outline: none !important;
}

.react-date-picker__inputGroup__input:invalid {
  background: transparent !important;
}
.react-calendar__tile--active {
  background: #fe697d !important;
  color: white;
}

@keyframes moveDownUp {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

@media (min-width: 1440px) {
  .content {
    align-items: center;
    /* height: calc(100vh - 95px); */
  }
}

@media (max-width: 1080px) {
  .cat-img {
    height: 400px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .cloud-left {
    height: 33px;
    margin-top: 94px;
    margin-bottom: 40px;
    margin-left: -35px;
    margin-right: 0px;
  }

  .blue-ball-left {
    margin-left: 10px;
  }

  .cat-img {
    margin-left: 0px;
  }

  .content {
    align-items: center;
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .header {
    align-items: center;
    text-align: center;
  }

  .iframe-container {
    padding-bottom: 56.25%; 
  }
  .cat-img {
    height: 250px !important;
    margin-left: 0px;
  }

  .cloud-right {
    height: 28px;
    margin-top: 17px;
    margin-bottom: 57px;
    margin-left: 0px;
  }

  .blue-ball-left {
    margin-top: 0px;
    height: 26px;
    display: block;
    margin-left: 0px;
  }

  .cloud-left {
    height: 42px;
    margin-top: 10px;
    margin-bottom: 49px;
    margin-left: 5px;
    margin-right: -15px;
  }

  .cards-conatiner {
    justify-content: center;
  }

  .card-first {
    height: 150px;
  }

  .card-second {
    height: 150px;
  }

  .content {
    align-items: center;
    flex-direction: column;
    gap: 60px;
  }

  .page-not-found-content {
    padding-top: 30px;
}
.page-not-found-content h2 {
  font-size: 30px;
  margin-top: -30px;
}
.head-name {
  font-size: 30px;
  font-weight: 600;
}
.side-info {
  margin-top: 0;
  margin-bottom: 0;
}
.landing-container {
  padding: 0 15px;
}
.login-cover {
  display: none;
}
.login-form {
  width: 100%;
}
.cards-conatiner {
  flex-wrap: nowrap;
  gap: 0;
}
.card-cover-3 {
  /* margin-left: -20px; */
  z-index: 9;
}

.card-cover-4 {
  /* margin-top: 100px;
  margin-left: -50px; */
}
.card-cover-2 {
  /* margin-top: 100px; */
  /* margin-left: -50px; */
}


.game-wrapper {
  height: calc(80vh - 100px);
}
}

@media (max-width: 480px) {


  .cloud-right {
    height: 16px;
  }

  .ball-right {
    height: 13px;
  }

  .cloud-left {
    height: 20px;
  }

  .blue-ball-left {
    height: 20px;
  }

  .red-ball-left {
    height: 16px;
  }

  .login-button, .register-button {
    padding: 10px 16px;
    border-radius: 22px;
  }

  .header-buttons {
    display: flex;
    gap: 10px;
    align-items: center;
  }


  .header-phone-view {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin-top: 20px;
  }

  .leader-board-link {
    font-size: 16px;
  }

  .pagination {
    padding-bottom:  70px;
  }

   
}

@media (max-width: 375px) {
  .cat-img {
    height: 180px !important;
  }
}


.brd{
  border: 1px solid red;
}



.reset-password-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}


.game-status-card {
  background-color: #FFD7A6 ;
  margin-top: 10px ;
  padding: 20px ;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.game-status-card p {
  margin:  0px;
}




.ok-button {
  cursor: pointer;
  border: none;
  padding: 10px 60px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  border-radius: 30px;
  border-bottom: 3px solid #e05567;
  background: #fe697d;
  background-size: 200% 100%;
  background-position: right bottom;

}

.pending-title {
  font-size: 24px;
  font-weight: 600;
  color: #B94B36;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pending-subtitle {
  font-size: 18px;
  font-weight: 600;
  
  margin-top: 10px;
  margin-bottom: 10px;
}

.game-status-card img {
  width: 50px;
  height: 50px;
}
  