.cat-card {
    max-width: 230px;
    width: 100%;
    height: 100%;
    padding: 16px;
    /* overflow: hidden; */
    border-radius: 10px;
    background-color: #ffcb8fb9;
    text-align: center;
    gap: 8px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    
}

.game-status {
    position:  absolute;
    top: 0;
    right: 0;
    background-color:#AFE1AF;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    z-index: 9999;
    padding: 4px 8px;
    border-radius: 10px;
}

.cat-game-status {
    position:  absolute;
    top: 10px;
    right: 10px;
 
    color: #000;
    font-size: 18px;
    font-weight: 600;
    
    padding: 4px 8px;
    width: fit-content;
    border-radius: 10px;
   
  }

  .new-game {
    background-color: #FF5733 ;
  }

  .completed-game {
    background-color: #AFE1AF;
  }

.home-slider a {
    text-decoration: none;
}


.cat-card p {
    font-weight: 600;
    font-size: 16px;
    color: #000;
    margin-bottom: 0px;
    
}

.cat-card img  {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}


.home-slider .slick-slide > div {
    margin: 0 10px;
   
  }
 .home-slider .slick-list {
    margin: 0 -10px;
  }

  .sub-cat-wrapper {
    display: flex;
    
    justify-content: center;
    align-items: center;
    height: calc(100vh - 500px);
    gap: 16px;
    flex-wrap: wrap;
  }

.back-button {
    min-width: 80px;
    border: none;
    padding: 10px 20px;
    color: white;
    font-weight: 500;
    font-size: 14px;
    border-radius: 30px;
    border-bottom: 3px solid #13080a;
    background: #444142;
    position: absolute;
    top: 70px;
    transform: translateY(-50%);
    transition: all 0.5s ease-out;
    cursor: pointer;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 4px;
  
}

.back-button:hover {
    background: #130e0f;
    border-bottom: 3px solid #1a1516;
    color: white;
}

.leader-board-link {
   
    color: #FE697D;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.5px;
    text-transform: capitalize;  
    text-decoration: underline   ;
    font-size: 20px;  
}

.leader-board-link:hover {
    color: #B94B36;
}


.not-a-user-button {
   color: #B94B36;
    
}

.not-a-user-button:hover {
    color: #000;
}



@media screen and (max-width: 768px) {
    .back-button {
        top:50px;
        padding: 6px 14px;
    }
}


@media screen and  (max-width: 1000px) and ( orientation:landscape) {

    

    
    .sub-cat-wrapper, .game-wrapper{
        position: absolute;
        top: 95px;
        left: 50%;
        width: 59%;
        height: 71%;
        border: 0;
        transform: translateX(-50%);
    }

    .sub-cat-wrapper {
        top: 125px;
    }

    .card-cover-1 {
        max-width: 445px !important;
    }

    .head-name {
        font-size: 20px  !important;
        
        margin-top: -14px;
        font-weight: 600;
        
    }

 

  
    .landing-wrapper {
        position: relative;
        background-image: url(http://localhost:3000/static/media/background.61f262a….jpg) !important;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 100vh;
        width: 100%;
        background-attachment: fixed;
    }
 }




 .password-input  {
    position: relative;
 }

 .eye-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    cursor: pointer;
 }
 
 
 
 /****** pagination ********/


 .pagination {
    padding-bottom: 20px;
 }
 .pagination a {
    border-color: #B94B36 !important;
   color : #fff !important;
    background-color: #e28133!important;
    
    
 }

 .pagination .disabled a {
    background-color: #e28133 !important;
    color : #f8f8f8a3 !important;
 }
 .pagination .disabled {
    background-color: transparent !important;
    color : transparent !important;
    display: none;
 }


 .pagination .active a {
    background-color: #B94B36  !important;
    color: white !important; 
    border: 1px solid #B94B36 !important;
    
}

.pagination .active {
    background-color: transparent !important;
    color: white;
    border: none !important;
}
