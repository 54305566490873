@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&family=Jost:ital,wght@0,100..900;1,100..900&display=swap");


body {
  margin: 0;
  padding: 0;
  background: #fff5dc !important;
  font-family: "Jost", sans-serif !important;
}

body > *, button,  p {
  font-family: "Jost", sans-serif !important;
}

h1, h2, h3, h4, h5, h6{
  font-family: "Fredoka", sans-serif!important;
  margin: 0;
}

.goverment-logo {
  position: fixed;
  width: 200px;
  top: 50%;
  left: 25px;
  transform: rotate(-90deg) translateX(-50%);
  transform-origin: left;
}


.goverment-logo img {
  width: 100%;
}

@media screen and (max-width: 768px){
.goverment-logo{
  transform: translateX(-50%);
  bottom: 10px;
  left: 50%;
  top: unset;
}
}